'use client';

import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import type { SiteMessagingStore } from '~/global/site-messaging/SiteMessaging.store';
import type { SiteMessageTypeKey } from '~/global/site-messaging/SiteMessage.model';

import { useGlobalContext } from '~/global/Contexts/Global.context';

type Props = {
	className?: JSX.IntrinsicElements['div']['className']
	filter?: SiteMessageTypeKey | SiteMessageTypeKey[]
};

export const SiteMessaging = observer(({ filter, className }: Props) => {
	const { siteMessagingStore }: { siteMessagingStore: SiteMessagingStore } = useGlobalContext();
	const theFilter = Array.isArray(filter) && filter !== undefined ? filter : [filter];
	const siteMessages = !filter
		? siteMessagingStore.leadingSiteMessages
		: siteMessagingStore.locationSpecificSiteMessages.filter(message => theFilter.includes(message.type));

	if (!siteMessages?.length) {
		return null;
	}
	return (
		<div data-qa="site-messaging-holder" className={cn('tw-space-y-4', className)}>
			{
				siteMessages.map((siteMessage, index) => {
					const key = `site-message-${index}`;

					if (siteMessage.template === 'CUSTOM') {
						return <div key={key} dangerouslySetInnerHTML={{ __html: siteMessage.message }} />;
					}
					return (
						<div
							key={key}
							className={cn('print:tw-hidden')}
							role="alert"
						>
							<h2
								className={cn('tw-p-3 tw-text-white tw-text-center tw-text-base [&_*]tw-text-base [&>*]:tw-m-0 [&_*]:tw-text-white', {
									'tw-bg-gray-300': siteMessage.template === 'BANNER_GREY',
									'tw-bg-red': siteMessage.template === 'BANNER_RED',
								})}
								dangerouslySetInnerHTML={{ __html: siteMessage.message }}
							/>
						</div>
					);
				})
			}
		</div>
	);
});

// eslint-disable-next-line import/no-default-export
export default SiteMessaging;
